<template>
  <div>
    <section class="section is-main-section">
      <card-component
          :title="
					$route.params.id
						? `${form.name} Düzenle`
						: 'Banka Hesabı Oluştur'
				"
          icon="domain">
        <form @submit.prevent="save">
          <b-field grouped group-multiline>
            <b-field label="Ödeme Yöntemi" expanded>
              <b-select
                  size="is-small"
                  v-model="form.bankId"
                  expanded
                  required
                  @change.native="
									form.method = banks.find(
										(item) => item.uuid === form.bankId
									).type
								">
                <option :value="null">Seçim Yapınız</option>
                <option
                    :value="bank.uuid"
                    v-for="bank in banks"
                    :key="bank.uuid">
                  {{ bank.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Method" expanded>
              <b-input
                  size="is-small"
                  v-model="form.method"
                  icon="domain"
                  placeholder="Method"
                  name="method"/>
            </b-field>
            <!--
            <b-field label="Banka" expanded v-if="form.method === 'BANK_TRANSFER' || form.method === 'FOREX'">
              <b-select size="is-small" v-model="form.bankName" expanded required>
                <option :value="null">Seçim Yapınız</option>
                <option :value="bank.uuid" v-for="bank in bankLists" :key="bank.uuid">{{ bank.name }}</option>
              </b-select>
            </b-field>
          -->
            <b-field label="Hesap Adı" expanded>
              <b-input
                  size="is-small"
                  v-model="form.name"
                  icon="domain"
                  placeholder="Hesap Adı"
                  name="name"
                  required/>
            </b-field>
            <b-field label="IBAN" expanded>
              <b-input
                  size="is-small"
                  v-model="form.iban"
                  icon="domain"
                  placeholder="IBAN"
                  name="iban"
                  required
                  @keyup.native="ibanValid"/>
              <img
                  v-if="
									bankLogo &&
									(form.method === 'BANK_TRANSFER' ||
										form.method === 'FOREX')
								"
                  :src="bankLogo"
                  width="100"/>
            </b-field>
          </b-field>
          <hr/>

          <b-field
              grouped
              group-multiline>
            <b-field label="Terminal No" expanded>
              <b-input
                  v-model="form.integrationSettings.CLIENT_CODE"
                  size="is-small"/>
            </b-field>
            <b-field label="Client Username" expanded>
              <b-input
                  v-model="
									form.integrationSettings.CLIENT_USERNAME
								"
                  size="is-small"/>
            </b-field>
            <b-field label="Client Password" expanded>
              <b-input
                  v-model="
									form.integrationSettings.CLIENT_PASSWORD
								"
                  size="is-small"/>
            </b-field>
            <b-field label="GUID" expanded>
              <b-input
                  v-model="form.integrationSettings.GUID"
                  size="is-small"/>
            </b-field>
          </b-field>

          <hr/>
          <b-field group-multiline grouped>
            <b-field label="Günlük Yatırım Limiti" expanded>
              <b-input
                  size="is-small"
                  v-model="form.dailyAmountLimit"
                  icon="currency-usd"
                  placeholder="Günlük Yatırım Limiti"
                  name="dailyAmountLimit"
                  required/>
            </b-field>
            <b-field label="Minimum Yatırım Limiti" expanded>
              <b-input
                  size="is-small"
                  v-model="form.minimumLimit"
                  icon="currency-usd"
                  placeholder="Minimum Limit"
                  name="minimumLimit"
                  required/>
            </b-field>
            <b-field label="Maksimum Yatırım Limiti" expanded>
              <b-input
                  size="is-small"
                  v-model="form.maximumLimit"
                  icon="currency-usd"
                  placeholder="Maksimum Limit"
                  name="maximumLimit"
                  required/>
            </b-field>
            <b-field
                label="Grup"
                expanded
                v-if="!$store.state.user.siteId">
              <b-input
                  size="is-small"
                  v-model="form.group"
                  icon="domain"
                  placeholder="Grup"
                  name="group"
                  required/>
            </b-field>
            <b-field
                label="Auto Bank"
                v-if="
								form.method === 'BANK_TRANSFER' && theme === '0'
							">
              <b-checkbox
                  size="is-small"
                  v-model="form.isAuto"
                  icon="domain"
                  name="isAuto"/>
            </b-field>
            <b-field
                label="FAST"
                v-if="
								form.method === 'BANK_TRANSFER' && theme === '0'
							">
              <b-checkbox
                  size="is-small"
                  v-model="form.fast"
                  icon="domain"
                  name="isAuto"/>
            </b-field>
          </b-field>
          <hr/>
          <b-field group-multiline grouped>
            <b-field expanded label="Tür">
              <b-select
                  size="is-small"
                  v-model="form.type"
                  expanded>
                <option
                    :value="key"
                    v-for="(
										item, key
									) in enums.bankAccountTypes"
                    :key="key">
                  {{ item }}
                </option>
              </b-select>
            </b-field>
            <b-field expanded label="Gösterim Türü">
              <b-select
                  size="is-small"
                  v-model="form.showType"
                  expanded>
                <option
                    :value="key"
                    v-for="(
										item, key
									) in enums.bankAccountShowTypes"
                    :key="key">
                  {{ item }}
                </option>
              </b-select>
            </b-field>
          </b-field>
          <hr v-if="form.showType === 'PRIVATE'"/>
          <b-field
              expanded
              label="Gösterilecek Siteler"
              v-if="form.showType === 'PRIVATE'">
            <b-select
                multiple
                size="is-small"
                v-model="form.sites"
                expanded>
              <option
                  :value="item.uuid"
                  v-for="item in sites"
                  :key="item.uuid">
                {{ item.name }}
              </option>
            </b-select>
          </b-field>
          <card-component
              title="VIP Seçimler"
              v-if="form.type === 'VIP'">
            <b-field group-multiline grouped>
              <b-field expanded label="Site">
                <b-select
                    size="is-small"
                    v-model="tmp.siteId"
                    expanded>
                  <option
                      :value="item.uuid"
                      v-for="item in sites"
                      :key="item.uuid">
                    {{ item.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field expanded label="Kullanıcı ID">
                <b-input size="is-small" v-model="tmp.userId"/>
                <p class="control">
                  <b-button
                      size="is-small"
                      type="is-success"
                      icon-left="plus"
                      @click="addUser"
                  >Ekle
                  </b-button
                  >
                </p>
              </b-field>
            </b-field>
            <br/>
            <div
                class="b-table"
                v-if="form.type === 'VIP' && form.users.length > 0">
              <div class="table-wrapper has-mobile-cards">
                <table
                    class="table is-striped is-narrow is-hoverable">
                  <thead>
                  <tr>
                    <th>Site</th>
                    <th>Kullanıcı ID</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tr
                      v-for="(user, key) in form.users"
                      :key="key">
                    <td class="is-actions-cell">
                      <div class="th-wrap">
                        {{
                          sites.length > 0
                              ? sites.find(
                                  (item) =>
                                      item.uuid ===
                                      user.siteId
                              ).name
                              : "-"
                        }}
                      </div>
                    </td>
                    <td class="is-actions-cell">
                      <div class="th-wrap">
                        {{ user.userId }}
                      </div>
                    </td>
                    <td class="is-actions-cell">
                      <b-button
                          size="is-small"
                          type="is-danger"
                          @click="removeUser(key)"
                      >Kaldır
                      </b-button
                      >
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </card-component>
          <hr/>
          <b-field grouped group-multiline v-if="form.bankId && banks.length > 0 && banks.find((item) => item.uuid === form.bankId).type === 'PAPARA'">
            <!--
            <b-field expanded label="E-Mail Servisi">
              <b-select expanded size="is-small" v-model="form.isMailService">
                <option :value="true">Aktif</option>
                <option :value="false">Pasif</option>
              </b-select>
            </b-field>
            -->
            <b-field
                expanded
                label="E-Posta"
                message="Yandex Mail Ayarları üzerinden IMAP izni verilmelidir. Ayrıca IMAP ayarları kısmından Portal şifresi ile giriş yetkisi tanımlanmalıdır. ">
              <b-input
                  size="is-small"
                  v-model="form.email"
                  type="email"
                  icon="email"
                  placeholder="...@yandex.com"
                  name="email"/>
            </b-field>
            <b-field expanded label="Parola">
              <b-input
                  size="is-small"
                  v-model="form.password"
                  type="password"
                  icon="lock"
                  placeholder="********"
                  name="password"/>
            </b-field>
          </b-field>
          <hr/>
          <b-field group-multiline grouped>
            <!--
            <b-field label="Varsayılan Hesap" message="Bankanın otomatik işlem yapılacak hesabını belirler.">
              <b-switch size="is-small" v-model="form.isDefault">{{ form.isDefault ? 'Evet' : 'Hayır' }}</b-switch>
            </b-field>
            -->
            <b-field label="Durum">
              <b-select v-model="form.status" size="is-small">
                <option value="ENABLED">Aktif</option>
                <option value="DISABLED">Pasif</option>
                <option value="BLOCKED">Bloke</option>
                <!--<option value="RESERVED">Yedek</option>-->
              </b-select>
            </b-field>
            <b-field label="Sıra">
              <b-numberinput
                  size="is-small"
                  v-model="form.sequence"
                  icon="domain"
                  placeholder="Sıra"
                  name="sequence"
                  min="0"
                  required/>
            </b-field>
          </b-field>
          <hr/>
          <div class="is-flex is-justify-content-end is-flex-grow-1">
            <div class="control mr-2">
              <b-button
                  native-type="submit"
                  type="is-primary"
                  size="is-small">
                {{ $route.params.id ? "Güncelle" : "Ekle" }}
              </b-button>
            </div>
            <div class="control">
              <b-button
                  type="is-primary is-outlined"
                  size="is-small"
                  @click="$router.go(-1)"
              >Geri Dön
              </b-button
              >
            </div>
          </div>
        </form>
      </card-component>
    </section>
  </div>
</template>

<script>
import mapValues from "lodash/mapValues"
import CardComponent from "@/components/CardComponent"
import {BankAccountService as Service, BankListService, BankService, SiteService,} from "@/services"
import BankCreateOrUpdate from "@/views/Bank/CreateOrUpdate"

export default {
  name: "BankCreateOrUpdate",
  components: {CardComponent},
  data() {
    return {
      isLoading: false,
      form: {
        name: null,
        bankId: null,
        iban: null,
        type: "STANDARD",
        showType: "ALL",
        group: "ALL",
        method: "PAPARA",
        isMailService: true,
        email: null,
        password: null,
        defaultEmail: null,
        dailyLimit: 0,
        dailyAmountLimit: 0,
        minimumLimit: null,
        maximumLimit: null,
        status: "ENABLED",
        isAuto: false,
        fast: false,
        isDefault: false,
        sites: [],
        users: [],
        sequence: 0,
        integrationSettings: {
          CLIENT_CODE: null,
          CLIENT_USERNAME: null,
          CLIENT_PASSWORD: null,
          GUID: null,
          ACCOUNT_NUMBER: null,
          TENANT_ID: null,
        },
        toMatch: null,
      },
      banks: [],
      sites: [],
      bankLists: [],
      tmp: {
        siteId: null,
        userId: null,
      },
      bankLogo: null,
    }
  },
  mounted() {
    if (this.$route.params.id) this.getData()
    this.getSites()
    this.getBanks()
    this.getBankLists()
  },
  methods: {
    ibanValid() {
      if (this.$route.params.id) {
      }

      if (this.form.iban.length >= 9) {
        const bankCode = this.form.iban.slice(6, 9)
        const bank = this.bankLists.find(
            (item) => item.code === bankCode
        )
        if (bank) {
          this.form.bankName = bank.uuid
          this.bankLogo = bank.logo
        } else {
          this.bankLogo = null
          console.log("banka yok")
        }
      } else {
        this.bankLogo = null
      }
    },
    getBanks() {
      BankService.all({status: 1}).then(
          (res) => (this.banks = res.data)
      )
    },
    getBankLists() {
      BankListService.all().then((res) => (this.bankLists = res.data))
    },
    getSites() {
      SiteService.all({status: 1}).then(
          (res) => (this.sites = res.data)
      )
    },
    getData() {
      Service.edit(this.$route.params.id).then((res) => {
        let sites = []
        res.data.sites.map((item) => sites.push(item.siteId))
        if (!res.data.integrationSettings)
          res.data.integrationSettings =
              this.form.integrationSettings
        this.form = {...res.data, sites}
      })
    },
    addBank() {
      this.openModal({
        component: BankCreateOrUpdate,
        props: {modal: true},
        close: this.getBanks,
      })
    },
    save() {
      if (
          this.form.showType === "PRIVATE" &&
          this.form.sites.length === 0
      ) {
        this.$buefy.toast.open({
          message: "Lütfen gösterilecek site seçiniz!",
          type: "is-danger",
        })
        return
      }

      if (
          this.form.method === "BANK_TRANSFER" ||
          this.form.method === "FOREX"
      ) {
        if (!this.validateTRIBAN(this.form.iban)) {
          this.$buefy.toast.open({
            message: "Geçersiz IBAN!",
            type: "is-danger",
          })
          return
        }
      }

      const form = this.form
      form.siteId =
          this.form.showType === "PRIVATE" ? form.siteId : null
      Service[this.$route.params.id ? "update" : "create"](this.form)
          .then(() => {
            this.$buefy.toast.open({
              message: this.$route.params.id
                  ? "Güncellendi"
                  : "Eklendi",
              type: "is-success",
            })
            this.reset()
            this.$router.go(-1)
          })
          .catch((err) =>
              this.$buefy.toast.open({
                message: err.message,
                position: "is-top-right",
                type: "is-danger",
              })
          )
    },
    reset() {
      this.form = mapValues(this.form, (item) => {
        if (item && typeof item === "object") {
          return []
        }
        return null
      })
    },
    addUser() {
      if (!this.tmp.userId || !this.tmp.siteId) return
      const control = this.form.users.find(
          (item) =>
              item.userId === this.tmp.userId &&
              item.siteId === this.tmp.siteId
      )
      if (control) return
      this.form.users.push(this.tmp)
      this.tmp = {siteId: null, userId: null}
    },
    removeUser(key) {
      let users = []
      this.form.users.map((item, itemKey) =>
          key !== itemKey ? users.push(item) : null
      )
      this.form.users = [...users]
    },
  },
}
</script>
